import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Voucher from './Voucher';
import {
  checkVoucherQrCode,
  getQrCodeStatus,
  getUser,
  retrieveGameUser,
  setQrCodeValidation,
  updateUserInfo,
} from '../../state/modules/data';

const mapStateToProps = (state: any) => ({
  userInfo: getUser(state),
  qrCodeStatus: getQrCodeStatus(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  checkVoucherQrCode: (payload: any) => dispatch(checkVoucherQrCode(payload)),
  setQrCodeValidation: (payload: any) => dispatch(setQrCodeValidation(payload)),
  // retrieveGameUser: () => dispatch(retrieveGameUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
