import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Experience from './experience'
import { checkGameQrCode, getQrCodeStatus, getUser, retrieveGameUser, setQrCodeValidation } from '../../state/modules/data';
import { EnumQrCodeValidation } from '../../utils/commons.enum';

const mapStateToProps = (state: any) => ({
    userInfo: getUser(state),
    qrCodeStatus: getQrCodeStatus(state)
})
    const mapDispatchToProps = (dispatch: Dispatch) => ({
        retrieveGameUser: () => dispatch(retrieveGameUser()),
        checkGameQrCode: (payload: string)=> dispatch(checkGameQrCode(payload)),
        setQrCodeStatus: (payload: EnumQrCodeValidation)=> dispatch(setQrCodeValidation(payload))
    })    

export default connect(mapStateToProps, mapDispatchToProps)(Experience);
