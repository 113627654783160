import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Card, CardBody, Image, ResponsiveContext } from 'grommet';
import { Home } from 'grommet-icons';
import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';

import percentageImage from '../../images/utils/20perc.png';

const StyledBox = styled(Box)`
  outline: none;
  user-select: none;
`;

export const CardPolicy = ({ title, subtitle, experience }: any) => {
  const size = React.useContext(ResponsiveContext);
  const { t, i18n } = useTranslation();
  const click = useCallback(() => {
    navigate(`/`);
  }, []);

  return (
    <Card
      style={{ borderRadius: 30, width: '100%', padding: 0, marginBottom: 50 }}
      background="white"
      elevation="none"
    >
      <CardBody>
        <Box height="100%">
          <Box style={{ padding: 20 }}>
            <div>
              <span style={{ fontWeight: '900', fontSize: 22 }}>
                {t('rules_voucher_title')}
              </span>
            </div>
            <div style={{ marginBottom: 40, marginTop: 10 }}>
              <span style={{ fontWeight: '500', fontSize: 16 }}>
                {' '}
                {t('rules_voucher_subtitle')}
              </span>
            </div>
          </Box>

          <Image
            fit={'cover'}
            src={percentageImage}
            style={{ width: '100%', maxHeight: 126, paddingBottom: 20 }}
          />
        </Box>
      </CardBody>
    </Card>
  );
};
