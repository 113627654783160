import * as React from 'react';
import { Box, ResponsiveContext, Card, CardBody } from 'grommet';
import { useCallback } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Home } from 'grommet-icons';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CardStarLogo } from '../card-star-logo/CardStarLogo';

export const CardBrandProgress = ({
  image,
  title,
  subtitle,
  brandStars,
  onCLickHaier,
  onClickHoover,
  onClickCandy,
}: any) => {
  const size = React.useContext(ResponsiveContext);
  const { t, i18n } = useTranslation();
  const click = useCallback(() => {
    navigate(`/`);
  }, []);

  return (
    <Card
      style={{ borderRadius: 30, width: '100%', height: 'auto' }}
      height="medium"
      width="small"
      background="white"
      elevation="none"
    >
      <Box
        align="left"
        style={{ height: 'auto', margin: 20, marginBottom: 10 }}
      >
        <span style={{ fontWeight: '900', fontSize: 22, marginBottom: 10 }}>
          {t('experience_title')}
        </span>
        <span style={{ fontWeight: '500', fontSize: 16 }}>
          {t('experience_subtitle')}
        </span>
      </Box>

      <CardBody pad="medium" style={{ paddingBottom: 0, marginTop: 8 }}>
        <CardStarLogo
          onClickCard={() => onCLickHaier('HAIER')}
          brand="HAIER"
          title={t('haier')}
          subtitle={t('experiences')}
          experience={brandStars?.starHaier}
        />
        <CardStarLogo
          onClickCard={() => onClickHoover('HOOVER')}
          brand="HOOVER"
          title={t('hoover')}
          subtitle={t('experiences')}
          experience={brandStars?.starHoover}
        />
        <CardStarLogo
          onClickCard={() => onClickCandy('CANDY')}
          brand="CANDY"
          title={t('candy')}
          subtitle={t('experiences')}
          experience={brandStars?.starCandy}
        />
      </CardBody>
    </Card>
  );
};
