import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  ResponsiveContext,
  Image,
  Button,
  Text,
  Footer,
  Spinner,
} from 'grommet';
import {ToastContainer, toast} from 'react-toastify';
import {injectStyle} from 'react-toastify/dist/inject-style';

import styled, {css} from 'styled-components';
import Modal from 'react-modal';
import './experience.css';
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import {QrReader} from 'react-qr-reader';
import {navigate} from 'gatsby';
import {Close} from '@mui/icons-material';

import DefaultPageContainer from '../../components/default-page-container/DefaultPageContainer';

import {CardBrandProgress} from '../../components/card-brand-progress/CardBrandProgress';
import {CardPolicy} from '../../components/card-policy/CardPolicy';

import zeroStelle from '../../images/utils/0stelle.png';
import unaStella from '../../images/utils/1stelle.png';
import dueStelle from '../../images/utils/2stelle.png';
import treStelle from '../../images/utils/3stelle.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {BottomNavigation} from '../../components/bottom-navigation/BottomNavigation';
import WinningPage from '../../components/winning-page/WinningPage';
import {EnumQrCodeValidation} from '../../utils/commons.enum';

const StyledBox = styled(Box)`
  outline: none;
  user-select: none;
`;

let allowScan = true;

// CALL IT ONCE IN YOUR APP
if (typeof window !== 'undefined') {
  injectStyle();
}

export const Overlay = () => (
  <div>
    <svg
      width="50px"
      viewBox="0 0 100 100"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        // transform: `translate(50%, 50%)`,
        boxSizing: 'border-box',
        border: '80px solid rgba(0, 0, 0, 0.3)',
        zIndex: 1,
      }}
    >
      <path
        fill="none"
        d="M13,0 L0,0 L0,13"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      />
      <path
        fill="none"
        d="M0,87 L0,100 L13,100"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      />
      <path
        fill="none"
        d="M87,100 L100,100 L100,87"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      />
      <path
        fill="none"
        d="M100,13 L100,0 87,0"
        stroke="rgba(255, 0, 0, 0.5)"
        strokeWidth="5"
      />
    </svg>
  </div>
);

const customStyles = {
  content: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    top: 'auto',
    right: '0',
    left: '0',
    bottom: '0',
    borderRadius: 30,
  },
};

const customStylesWinningPage = {
  content: {
    padding: '0',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
  },
};

interface ExperienceProps {
  userInfo: {
    starHaier: number;
    starHoover: number;
    starCandy: number;
  };
  qrCodeStatus: EnumQrCodeValidation;
  retrieveGameUser: () => void;
  checkGameQrCode: (idGioco: string) => void;
  setQrCodeStatus: (paylaod: EnumQrCodeValidation) => void;
}

const Experience = ({
                      userInfo,
                      qrCodeStatus,
                      retrieveGameUser,
                      checkGameQrCode,
                      setQrCodeStatus,
                    }: ExperienceProps) => {
  const size = React.useContext(ResponsiveContext);
  const {t, i18n} = useTranslation();

  const [data, setData] = useState('No result');
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalWiningIsOpen, setModalWinningIsOpen] = React.useState(false);
  const [isQrScannerOpen, setIsQrScannerOpen] = React.useState(false);
  const [modalContentStar, setModalContentStar] = React.useState(0);
  const [isQrScannerActive, setIsQrScannerActive] = useState(true);
  const [showAlertAccessPhoto, setShowAlertAccessPhoto] = React.useState(false);
  const [cameraPermissions, setCameraPermissions] = React.useState(true);

  useEffect(() => {
    if (qrCodeStatus === EnumQrCodeValidation.SUCCESS) {
      setIsQrScannerOpen(false);
    }
  }, [qrCodeStatus]);

  useEffect(() => {
    if (
      userInfo.starCandy >= 3 &&
      userInfo.starHaier >= 3 &&
      userInfo.starHoover >= 3
    ) {
      setIsQrScannerOpen(false);
      setModalWinningIsOpen(true);
    }
  }, [userInfo]);

  useEffect(() => {
    retrieveGameUser();
  }, []);

  const showRightToast = useCallback(() => {
    switch (qrCodeStatus) {
      case EnumQrCodeValidation.SUCCESS:
        return toast.success(t('toast_qr_code_valid'), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          onClose: () => {
            setIsQrScannerActive(true);
            allowScan = true;
            setQrCodeStatus(EnumQrCodeValidation.IDLE);
          },
        });
      case EnumQrCodeValidation.ALREADY_SCANNED:
        return toast.warn(t('toast_qr_code_already_scan'), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          onClose: () => {
            setIsQrScannerActive(true);
            allowScan = true;
            setQrCodeStatus(EnumQrCodeValidation.IDLE);
          },
        });
      case EnumQrCodeValidation.GENERIC_ERROR:
        return toast.error(t('toast_qr_code_generic_error'), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          onClose: () => {
            setIsQrScannerActive(true);
            allowScan = true;
            setQrCodeStatus(EnumQrCodeValidation.IDLE);
          },
        });
      default:
        return null;
    }
  }, [qrCodeStatus]);

  useEffect(() => {
    setTimeout(() => {
      showRightToast();
    }, 300);
  }, [showRightToast]);

  const brandStar = useMemo(() => {
    const stars = {
      starHaier: userInfo.starHaier,
      starHoover: userInfo.starHoover,
      starCandy: userInfo.starCandy,
    };
    return stars;
  }, [userInfo]);

  // useEffect(() => {
  //   const nav: any = navigator;
  //
  //   if (nav?.getUserMedia) {
  //     nav.getUserMedia(
  //       {
  //         video: true,
  //       },
  //       (d: any) => {
  //         console.log('[test] device', d)
  //         setShowAlertAccessPhoto(false);
  //         setTimeout(() => {
  //           setCameraPermissions(true)
  //         }, 1000)
  //       },
  //       (err: any) => {
  //         console.log('err denied photocamera', err);
  //         if (err.includes('Permission denied')) {
  //           setTimeout(() => {
  //             setShowAlertAccessPhoto(true);
  //           }, 3000);
  //         }
  //       }
  //     );
  //   }
  // }, []);

  const goTo = useCallback(() => {
    navigate(`/app/terms`);
  }, [navigate]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    console.log('wee');
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function openModalStars(brand: string) {
    if (brand === 'HOOVER') {
      setModalContentStar(userInfo.starHoover);
    } else if (brand === 'HAIER') {
      setModalContentStar(userInfo.starHaier);
    } else if (brand === 'CANDY') {
      setModalContentStar(userInfo.starCandy);
    }
    console.log(modalContentStar, brand);
    setModalIsOpen(true);
  }

  const handleQrReader = useCallback(
    (result: any, error: any) => {
      setTimeout(() => {
        if (result && isQrScannerActive && allowScan) {
          const idGioco = result?.text;
          if (idGioco && isQrScannerActive) {
            setIsQrScannerActive(false);
            allowScan = false;
            setQrCodeStatus(EnumQrCodeValidation.DATA_SENDING);
            checkGameQrCode(idGioco);
          }
          setData(idGioco);
        }
        if (error) {
          console.info(error);
        }
      }, 500);
    },
    [isQrScannerActive, qrCodeStatus, checkGameQrCode]
  );

  return (
    <>
      <DefaultPageContainer align="center" pad={{horizontal: 'large'}}>
        {/* {toastContainer} */}
        <Box style={{marginTop: 30}}>
          {isQrScannerOpen ? (
            <Box>
              <Box align="center" justify="center" alignContent="center">
                <Text
                  style={{fontSize: 22, fontWeight: '900', marginBottom: 10}}
                >
                  {t('experience_qr_title')}
                </Text>
                <Text
                  textAlign="center"
                  style={{fontSize: 16, fontWeight: '500'}}
                >
                  {t('experience_qr_subtitle')}
                </Text>
              </Box>
              <Box margin={{vertical: 'large'}}>
                {allowScan && cameraPermissions && isQrScannerOpen ? (
                  <QrReader
                    onResult={handleQrReader}
                    videoStyle={{width: '140%', marginLeft: '-20%'}}
                    constraints={{ facingMode: {ideal: 'environment'} }}
                    ViewFinder={() =>
                      modalIsOpen || modalWiningIsOpen ? null : <Overlay/>
                    }
                  />
                ) : (
                  <Box
                    style={{marginTop: 30}}
                    justify="center"
                    align="center"
                  >
                    {' '}
                    <Spinner
                      size={'medium'}
                      alignSelf="center"
                      justify="center"
                    />
                  </Box>
                )}
              </Box>
              {showAlertAccessPhoto && (
                <Box
                  style={{alignSelf: 'center', marginBottom: 40}}
                  align="center"
                  justify="center"
                >
                  <Text
                    textAlign="center"
                    style={{
                      fontSize: 16,
                      fontWeight: '900',
                      marginBottom: 10,
                      color: 'red',
                    }}
                  >
                    {' '}
                    {t('voucher_alert_access_photo')}{' '}
                  </Text>
                </Box>
              )}
            </Box>
          ) : (
            <>
              <Box style={{marginBottom: 40}}>
                <CardBrandProgress
                  onCLickHaier={(brand: string) => openModalStars(brand)}
                  onClickHoover={(brand: string) => openModalStars(brand)}
                  onClickCandy={(brand: string) => openModalStars(brand)}
                  brandStars={brandStar}
                />
              </Box>
            </>
          )}
        </Box>
      </DefaultPageContainer>
      <Footer justify="center" height="40px" style={{paddingBottom: 30}}>
        <BottomNavigation
          onLeftButtonPress={() => {
            setQrCodeStatus(EnumQrCodeValidation.IDLE);
          }}
          onRightButtonPress={() => {
            setIsQrScannerOpen(!isQrScannerOpen);
            setQrCodeStatus(EnumQrCodeValidation.IDLE);
          }}
          buttonTitle={
            isQrScannerOpen
              ? t('experience_qr_footer_button')
              : t('experience_footer_button')
          }
        />
      </Footer>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => afterOpenModal()}
        onRequestClose={() => closeModal()}
        style={customStyles}
        overlayClassName="Overlay"
      >
        <Box align="center">
          <Button
            style={{
              position: 'absolute',
              top: 20,
              right: 10,
              padding: 10,
              paddingLeft: 10,
              marginRight: 10,
              borderRadius: 50,
              backgroundColor: '#F5522E',
            }}
            icon={<Close style={{color: 'white'}}/>}
            color="#FFFFFF"
            plain
            onClick={() => closeModal()}
          />
          {modalContentStar === 0 && (
            <Image style={{marginTop: 30}} src={zeroStelle} height={100}/>
          )}
          {modalContentStar === 1 && <Image src={unaStella} height={100}/>}
          {modalContentStar === 2 && <Image src={dueStelle} height={100}/>}
          {modalContentStar > 2 && <Image src={treStelle} height={100}/>}
          <Text
            textAlign="center"
            style={{marginTop: 20, fontSize: 22, fontWeight: '900'}}
          >
            {modalContentStar === 0 && t('modal_title_0')}
            {modalContentStar === 1 && t('modal_title_1')}
            {modalContentStar === 2 && t('modal_title_1')}
            {modalContentStar > 2 && t('modal_title_3')}
          </Text>
          <Text
            textAlign="center"
            style={{fontSize: 16, fontWeight: '500', marginBottom: 40}}
          >
            {modalContentStar === 0 && t('modal_subtitle_0')}
            {modalContentStar === 1 && t('modal_subtitle_1')}
            {modalContentStar === 2 && t('modal_subtitle_1')}
            {modalContentStar > 2 && t('modal_subtitle_3')}
          </Text>
          <button
            className="buttonModal"
            type="button"
            onClick={() => {
              setIsQrScannerOpen(true);
              closeModal();
            }}
          >
            <Text>{t('experience_footer_button')}</Text>
          </button>
        </Box>
      </Modal>
      <Modal
        isOpen={modalWiningIsOpen}
        onAfterOpen={() => afterOpenModal()}
        onRequestClose={() => closeModal()}
        style={customStylesWinningPage}
        overlayClassName="Overlay"
      >
        <Box align="center">
          <WinningPage onClose={() => setModalWinningIsOpen(false)}/>
        </Box>
      </Modal>
      <ToastContainer/>
    </>
  );
};

export default Experience;
