import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import {
  Box,
  Heading,
  Layer,
  Paragraph,
  ResponsiveContext,
  Spinner,
} from 'grommet';
import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import { timeout } from 'rxjs';
import styled, { css } from 'styled-components';

import { CardImage } from '../../components/card-image/CardImage';
import DefaultPageContainer from '../../components/default-page-container/DefaultPageContainer';
import initialCardLogo from '../../images/utils/logo-card-iniziale.png';
import card20Perc from '../../images/utils/Card20perc.png';
import { getUserIdFromUrl } from '../../utils/getUserIdFromUrl';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

interface HomeProps {
  retrieveUser: (userId: string) => void;
}

const getWindowUrl = () => {
  if (typeof window !== 'undefined') {
    return window?.location?.pathname;
  }
  return null;
};

const Home = ({ retrieveUser }: HomeProps) => {
  const size = React.useContext(ResponsiveContext);
  const { t, i18n } = useTranslation();
  const { navigate } = useI18next();
  const [isLoading, setIsLoading] = useState(true);
  const goToTutorial = useCallback(() => {
    navigate(`/app/tutorial`);
  }, [navigate]);
  const goToRulesVoucher = useCallback(() => {
    navigate(`/app/vaucherRules`);
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <DefaultPageContainer align="center" pad={{ horizontal: 'large' }}>
        <Box width="100%">
          <Box align="center">
            <p style={{ fontWeight: '900', fontStyle: 'italic', fontSize: 26 }}>
              {t('home_title')}
            </p>
          </Box>

          <Box onClick={() => goToTutorial()}>
            <CardImage
              fitImage="contain"
              image={initialCardLogo}
              title={t('home_experience_title')}
              subtitle={t('home_experience_subtitle')}
            />
          </Box>

          <Box onClick={() => goToRulesVoucher()}>
            <CardImage
              fitImage="contain"
              image={card20Perc}
              title={t('home_voucher_title')}
              subtitle={t('home_voucher_subtitle')}
            />
          </Box>
        </Box>
      </DefaultPageContainer>
    </>
  );
};

export default Home;
