import * as React from 'react';
import { Box, ResponsiveContext, Card, CardBody, Image } from 'grommet';
import { useCallback } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Home } from 'grommet-icons';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Candy from '../../images/utils/StarCandy.png';
import Haier from '../../images/utils/StarHaier.png';
import Hoover from '../../images/utils/StarHoover.png';
import CandySpento from '../../images/utils/StarCandySpento.png';
import HaierSpento from '../../images/utils/StarHaierSpento.png';
import HooverSpento from '../../images/utils/StarHooverSpento.png';
import stella from '../../images/utils/Stella_piccola_accesa.png';
import stellaSpenta from '../../images/utils/Stella-disabilitata.png';

import './CardStarLogo.css';

const StyledBox = styled(Box)`
  outline: none;
  user-select: none;
`;

export const CardStarLogo = ({
  title,
  subtitle,
  experience,
  brand,
  onClickCard,
}: any) => {
  const size = React.useContext(ResponsiveContext);
  const { t, i18n } = useTranslation();
  const click = useCallback(() => {
    navigate(`/`);
  }, []);

  return (
    <Card
      style={{ borderRadius: 30, width: '100%', padding: 8, marginBottom: 10 }}
      className={experience >= 1 ? 'background_card' : ''}
      background="light-1"
      elevation="none"
      onClick={() => onClickCard()}
    >
      <CardBody>
        <StyledBox
          id="logo"
          height="100%"
          justify="center"
          flex
          direction="row"
          align="center"
          focusIndicator={false}
        >
          <Box>
            {brand === 'HAIER' ? (
              <Image
                src={experience >= 1 ? Haier : HaierSpento}
                style={{ width: 50, height: 50 }}
              />
            ) : null}
            {brand === 'HOOVER' ? (
              <Image
                src={experience >= 1 ? Hoover : HooverSpento}
                style={{ width: 50, height: 50 }}
              />
            ) : null}
            {brand === 'CANDY' ? (
              <Image
                src={experience >= 1 ? Candy : CandySpento}
                style={{ width: 50, height: 50 }}
              />
            ) : null}
          </Box>
          <StyledBox
            height="100%"
            justify="center"
            flex
            direction="column"
            align="left"
            focusIndicator={false}
            style={{ marginLeft: 10 }}
          >
            <div>
              <span style={{ fontSize: 18, fontWeight: '700' }}>{title}</span>
            </div>
            <div>
              <span style={{ fontSize: 14, fontWeight: '600' }}>
                {experience}/3 {subtitle}{' '}
              </span>
            </div>
          </StyledBox>
          <StyledBox
            height="100%"
            justify="center"
            align="center"
            flex
            direction="row"
          >
            <Image
              src={experience >= 1 ? stella : stellaSpenta}
              style={{ width: 30, height: 30 }}
            />
            <Image
              src={experience >= 2 ? stella : stellaSpenta}
              style={{ width: 30, height: 30 }}
            />
            <Image
              src={experience >= 3 ? stella : stellaSpenta}
              style={{ width: 30, height: 30 }}
            />
          </StyledBox>
        </StyledBox>
      </CardBody>
    </Card>
  );
};
