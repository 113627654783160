import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import TermsAndConditions from './TermsAndConditions';
import { updateUserInfo } from '../../state/modules/data';

const mapStateToProps = (_state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateUserInfo: (payload: any) => dispatch(updateUserInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
