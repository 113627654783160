import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  ResponsiveContext,
  Carousel,
  Image,
  Button,
  CheckBox,
  Text,
  Footer,
  Spinner,
} from 'grommet';
import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { QrReader } from 'react-qr-reader';
import { navigate } from 'gatsby';
import { rgba } from 'polished';
import { Home, Close } from '@mui/icons-material';
import SportsEsports from '@mui/icons-material/SportsEsportsOutlined';
import DefaultPageContainer from '../../components/default-page-container/DefaultPageContainer';

import { CardBrandProgress } from '../../components/card-brand-progress/CardBrandProgress';
import { CardPolicy } from '../../components/card-policy/CardPolicy';

import percentageImage from '../../images/utils/20perc.png';

import voucherError from '../../images/utils/VoucherError.png';
import qrCode from '../../images/utils/QRCode.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BottomNavigation } from '../../components/bottom-navigation/BottomNavigation';
import { Overlay } from '../experience/experience';
import { EnumQrCodeValidation } from '../../utils/commons.enum';

const StyledBox = styled(Box)`
  outline: none;
  user-select: none;
`;

let allowScan = true;

const customStyles = {
  content: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    top: 'auto',
    right: '0',
    left: '0',
    bottom: '0',
    borderRadius: 30,
  },
};
const Voucher = ({
  checkVoucherQrCode,
  userInfo,
  qrCodeStatus,
  setQrCodeValidation,
}: any) => {
  const size = React.useContext(ResponsiveContext);
  const { t, i18n } = useTranslation();
  const isVoucherAlreadyScanned = userInfo?.voucher;
  const [data, setData] = useState('No result');
  const [isRulesChecked, setIsRulesChecked] = useState(false);
  const [isQrScannerActive, setIsQrScannerActive] = useState(true);

  const [modalSuccessIsOpen, setModalSuccessIsOpen] = React.useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = React.useState(false);
  const [modalNotvalidIsOpen, setModalNotvalidIsOpen] = React.useState(false);
  const [showAlertAccessPhoto, setShowAlertAccessPhoto] = React.useState(false);

  const [isQrScannerOpen, setIsQrScannerOpen] = React.useState(true);

  // useEffect(() => {
  //   const nav: any = navigator;
  //
  //   if (nav?.getUserMedia) {
  //     nav.getUserMedia(
  //       {
  //         video: true,
  //       },
  //       (d: any) => {
  //         console.log('[test] device', d)
  //         setShowAlertAccessPhoto(false);
  //         setTimeout(() => {
  //           //
  //         }, 1000)
  //       },
  //       (err: any) => {
  //         console.log('err denied photocamera', err);
  //         if (err.includes('Permission denied')) {
  //           setTimeout(() => {
  //             setShowAlertAccessPhoto(true);
  //           }, 3000);
  //         }
  //       }
  //     );
  //   }
  // }, []);

  const closeModal = useCallback(() => {
    setModalSuccessIsOpen(false);
    setModalErrorIsOpen(false);
    setModalNotvalidIsOpen(false);
    setQrCodeValidation(EnumQrCodeValidation.IDLE);
    setIsQrScannerActive(true);
    allowScan = true;
  }, []);

  useEffect(() => {
    if (qrCodeStatus === EnumQrCodeValidation.SUCCESS) {
      setModalSuccessIsOpen(true);
    } else if (qrCodeStatus === EnumQrCodeValidation.GENERIC_ERROR) {
      setModalErrorIsOpen(true);
    } else if (qrCodeStatus === EnumQrCodeValidation.ALREADY_SCANNED) {
      setModalNotvalidIsOpen(true);
    }
  }, [qrCodeStatus]);

  useEffect(() => {
    if (qrCodeStatus === EnumQrCodeValidation.IDLE) {
      setIsQrScannerActive(true);
      allowScan = true;
    }
  }, [qrCodeStatus]);

  const handleQrReader = useCallback(
    (result: any, error: any) => {
      if (!!result && isQrScannerActive && allowScan) {
        const idVoucher = result?.text;
        if (
          idVoucher &&
          isQrScannerActive &&
          qrCodeStatus === EnumQrCodeValidation.IDLE
        ) {
          allowScan = false;
          setIsQrScannerActive(false);
          checkVoucherQrCode(idVoucher);
        }
        setData(idVoucher);
      }
      if (error) {
        console.info(error);
      }
    },
    [isQrScannerActive, qrCodeStatus, checkVoucherQrCode]
  );

  return (
    <>
      <DefaultPageContainer align="center" pad={{ horizontal: 'large' }}>
        <Box style={{ marginTop: 30 }}>
          <Box>
            <Box align="center" justify="center" style={{ marginBottom: 40 }}>
              <Text
                textAlign="center"
                style={{
                  fontSize: 22,
                  fontWeight: '900',
                  marginBottom: 10,
                  width: '100%',
                }}
              >
                {t('experience_qr_title')}
              </Text>
              <Text
                textAlign="center"
                style={{ fontSize: 16, fontWeight: '500' }}
              >
                {t('experience_qr_subtitle')}
              </Text>
            </Box>
            <Box style={{ minWidth: 215, minHeight: 215, marginBottom: 50 }}>
              <QrReader
                scanDelay={1000}
                onResult={handleQrReader}
                videoStyle={{ width: '140%', marginLeft: '-20%' }}
                constraints={{ facingMode: {ideal: 'environment'} }}
                ViewFinder={() =>
                  modalErrorIsOpen ||
                  modalNotvalidIsOpen ||
                  modalSuccessIsOpen ? null : (
                    <Overlay />
                  )
                }
              />
            </Box>
            {showAlertAccessPhoto && (
              <Box
                align="center"
                justify="center"
                style={{ marginBottom: 40, alignSelf: 'center' }}
              >
                <Text
                  textAlign="center"
                  style={{
                    fontSize: 16,
                    fontWeight: '900',
                    marginBottom: 10,
                    color: 'red',
                  }}
                >
                  {' '}
                  {t('voucher_alert_access_photo')}{' '}
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      </DefaultPageContainer>
      <Footer justify="center" height="40px" style={{ paddingBottom: 30 }}>
        <BottomNavigation
          buttonRightVisible={false}
          isCloseButton
          onLeftButtonPress={() => {
            setIsQrScannerOpen(false);
            setQrCodeValidation(EnumQrCodeValidation.IDLE);
            navigate(`/app/vaucherRules`);
          }}
          onCloseButtonPress={() => {
            setQrCodeValidation(EnumQrCodeValidation.IDLE);
            navigate(`/app/vaucherRules`);
          }}
          buttonTitle={
            isQrScannerOpen
              ? t('experience_qr_footer_button')
              : t('experience_footer_button')
          }
        />
      </Footer>
      <Modal
        isOpen={modalSuccessIsOpen}
        onRequestClose={() => closeModal()}
        style={customStyles}
        overlayClassName="Overlay"
      >
        <Box align="center">
          <Button
            style={{
              position: 'absolute',
              top: 20,
              right: 10,
              padding: 10,
              marginRight: 10,
              backgroundColor: '#F5522E',
              borderRadius: 50,
            }}
            icon={<Close />}
            color="#FFFFFF"
            plain={false}
            onClick={() => {
              setQrCodeValidation(EnumQrCodeValidation.IDLE);
              setModalSuccessIsOpen(false);
            }}
          />
          <Box
            align="center"
            justify="center"
            style={{ marginTop: 50, marginBottom: 40 }}
          >
            <Image
              src={percentageImage}
              height={110}
              fit="cover"
              style={{ maxHeight: 120 }}
            />
            <Text style={{ fontSize: 22, fontWeight: '900', marginTop: 10 }}>
              {t('voucher_modal_1_title')}
            </Text>
            <Text
              textAlign="center"
              style={{ fontSize: 16, fontWeight: '500', marginTop: 10 }}
            >
              {t('voucher_modal_1_subtitle')}
            </Text>
          </Box>
          <Box align="center" justify="center" flex direction="row">
            <Button
              style={{
                padding: 10,
                marginRight: 10,
                borderRadius: 50,
                backgroundColor: 'white',
                border: 'solid',
                borderWidth: 2.5,
                borderColor: rgba(19, 155, 232, 0.2),
              }}
              icon={<Home />}
              color="#139BE8"
              plain={false}
              onClick={() => {
                setQrCodeValidation(EnumQrCodeValidation.IDLE);
                setModalSuccessIsOpen(false);
                navigate(`/`);
              }}
            />
            <button
              className="buttonModal"
              type="button"
              onClick={() => {
                setQrCodeValidation(EnumQrCodeValidation.IDLE);
                setModalSuccessIsOpen(false);
                navigate(`/app/experience`);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: '900', marginTop: 10 }}>
                {t('voucher_button_modal_1')}
              </Text>
            </button>
          </Box>
        </Box>
      </Modal>
      <Modal
        isOpen={modalErrorIsOpen}
        onRequestClose={() => closeModal()}
        style={{
          ...customStyles,
          content: {
            ...customStyles.content,
            padding: 0,
          },
        }}
        overlayClassName="Overlay"
      >
        <Box
          align="center"
          //  style={{ backgroundColor: 'red' }}
        >
          <Button
            style={{
              position: 'absolute',
              top: 20,
              right: 10,
              padding: 10,
              marginRight: 10,
              backgroundColor: '#F5522E',
              borderRadius: 50,
            }}
            icon={<Close />}
            color="#FFFFFF"
            plain={false}
            onClick={() => {
              setQrCodeValidation(EnumQrCodeValidation.IDLE);
              setModalErrorIsOpen(false);
            }}
          />
          <Box align="center" justify="center" style={{ marginTop: 50 }}>
            <Image
              src={voucherError}
              // height={'110'}
              style={{ width: '100%', height: 'auto' }}
            />
            <Box
              style={{
                // backgroundColor: 'green',
                padding: 20,
              }}
            >
              <Text style={{ fontSize: 22, fontWeight: '900', marginTop: 10 }}>
                {t('voucher_modal_2_title')}
              </Text>
              <Box align="center">
                {' '}
                <Text
                  textAlign="center"
                  style={{ fontSize: 16, fontWeight: '500', marginTop: 10 }}
                >
                  {t('voucher_modal_2_subtitle')}
                </Text>
              </Box>
              <Box
                align="center"
                justify="center"
                flex
                direction="row"
                margin={{ top: '40px' }}
              >
                <button
                  className="buttonModal"
                  type="button"
                  onClick={() => {
                    setQrCodeValidation(EnumQrCodeValidation.IDLE);
                    setModalErrorIsOpen(false);
                  }}
                >
                  <Text
                    style={{ fontSize: 16, fontWeight: '900', marginTop: 10 }}
                  >
                    {t('voucher_button_modal_2')}
                  </Text>
                </button>
                <Button
                  style={{
                    padding: 10,
                    borderRadius: 50,
                    marginLeft: 10,
                    backgroundColor: 'white',
                    border: 'solid',
                    borderWidth: 2.5,
                    borderColor: rgba(19, 155, 232, 0.2),
                  }}
                  icon={<SportsEsports />}
                  color="#139BE8"
                  plain={false}
                  onClick={() => {
                    setQrCodeValidation(EnumQrCodeValidation.IDLE);
                    setModalErrorIsOpen(false);
                    navigate(`/app/experience`);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        isOpen={modalNotvalidIsOpen}
        onRequestClose={() => closeModal()}
        style={customStyles}
        overlayClassName="Overlay"
      >
        <Box align="center">
          <Button
            style={{
              position: 'absolute',
              top: 20,
              right: 10,
              padding: 10,
              marginRight: 10,
              backgroundColor: '#F5522E',
              borderRadius: 50,
            }}
            icon={<Close style={{ color: 'white' }} />}
            plain
            onClick={() => {
              setQrCodeValidation(EnumQrCodeValidation.IDLE);
              setModalNotvalidIsOpen(false);
            }}
          />
          <Box
            align="center"
            justify="center"
            style={{ marginTop: 50, marginBottom: 40 }}
          >
            <Image src={qrCode} height={110} />
            <Text style={{ fontSize: 22, fontWeight: '900', marginTop: 10 }}>
              {t('voucher_modal_3_title')}
            </Text>
            <Box align="center">
              {' '}
              <Text
                textAlign="center"
                style={{ fontSize: 16, fontWeight: '500', marginTop: 10 }}
                onClick={() => {
                  setQrCodeValidation(EnumQrCodeValidation.IDLE);
                  setModalNotvalidIsOpen(false);
                }}
              >
                {t('voucher_modal_3_subtitle')}
              </Text>
            </Box>
          </Box>
          <Box align="center" justify="center" flex direction="row">
            <Button
              style={{
                padding: 10,
                marginRight: 10,
                borderRadius: 50,
                backgroundColor: 'white',
                border: 'solid',
                borderWidth: 2.5,
                borderColor: rgba(19, 155, 232, 0.2),
              }}
              icon={<Home />}
              color="#139BE8"
              plain={false}
              onClick={() => {
                setQrCodeValidation(EnumQrCodeValidation.IDLE);
                setModalNotvalidIsOpen(false);
                navigate(`/`);
              }}
            />
            <button
              className="buttonModal"
              type="button"
              onClick={() => {
                setQrCodeValidation(EnumQrCodeValidation.IDLE);
                navigate(`/app/experience`);
              }}
            >
              <Text style={{ fontSize: 16, fontWeight: '900', marginTop: 10 }}>
                {t('voucher_button_modal_1')}
              </Text>
            </button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Voucher;
