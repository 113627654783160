import { graphql, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import ErrorFormatPage from '../components/error-format-page/ErrorFormatPage';
import Main from '../components/main/main';
import { getItem, StorageKeys } from '../services/storage-service';
import { retrieveUser, storeUser } from '../state/modules/data';

// markup
const Page = () => {
  const dispatch = useDispatch();

  function getWindowDimensions() {
    if (typeof window !== 'undefined') {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height,
      };
    }
    return null;
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const user = getItem(StorageKeys.USER);
    if (user?.id) {
      dispatch(storeUser(user));
      dispatch(retrieveUser(user?.id));
    }

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      console.log('Change Size Window', getWindowDimensions());
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, [dispatch]);

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[{ name: 'theme-color', content: '#EFF1F4' }]}
        title="fuorisalone"
      />
      {windowDimensions && windowDimensions.width > 767 ? (
        <ErrorFormatPage />
      ) : (
        <Main />
      )}
    </>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { in: [$language, "en"] } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
