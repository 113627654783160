import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Card, CardBody, Image, ResponsiveContext } from 'grommet';
import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';

export const CardImage = ({
  image,
  title,
  subtitle,
  textLeft,
  fitImage,
}: any) => {
  const size = React.useContext(ResponsiveContext);
  const { t, i18n } = useTranslation();
  const click = useCallback(() => {
    navigate(`/`);
  }, []);

  return (
    <Card
      style={{
        borderRadius: 30,
        width: '100%',
        marginBottom: 10,
        minHeight: textLeft ? 274 : 200,
      }}
      height="small"
      width="small"
      background="white"
      elevation="none"
    >
      <CardBody pad="medium" align={textLeft ? 'left' : 'center'}>
        <Image
          fit={fitImage}
          src={image}
          style={{
            borderRadius: textLeft ? 20 : 0,
            width: '100%',
            // maxWidth: 244,
            minHeight: 80,
          }}
        />
        <Box
          align={textLeft ? 'left' : 'center'}
          style={{ marginTop: 20, marginBottom: 10 }}
        >
          <span
            style={{
              fontSize: 22,
              lineHeight: 1.1,
              fontWeight: '900',
              marginBottom: 10,
              whiteSpace: 'pre-line',
            }}
          >
            {title}
          </span>
          <span style={{ fontSize: 16, fontWeight: '500' }}>{subtitle}</span>
        </Box>
      </CardBody>
    </Card>
  );
};
