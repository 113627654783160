import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Home from './home'
import { retrieveUser } from '../../state/modules/data';

const mapStateToProps = (state: any) => ({
})
    const mapDispatchToProps = (dispatch: Dispatch) => ({
        retrieveUser: (userId: string) => dispatch(retrieveUser(userId))
    })    

export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Home);
  