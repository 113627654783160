import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Card, CardBody, Footer, ResponsiveContext } from 'grommet';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { BottomNavigation } from '../../components/bottom-navigation/BottomNavigation';
import { CardPolicy } from '../../components/card-policy/CardPolicy';
import DefaultPageContainer from '../../components/default-page-container/DefaultPageContainer';
import ht from '../../utils/translation';
import ReactMarkdown from 'react-markdown';

export const WrapContainer = styled(Box)`
  padding: 20px;
  color: #383838;

  h1 {
    font-weight: 900;
    font-size: 22px;
    margin: 0;
    margin-bottom: 10px;
  }

  .main_subtitle {
    margin-bottom: 14px;
  }

  .list {
    margin-top: 10px;
    white-space: pre-line;

    div {
      margin-top: 30px;
    }

    p {
      margin-top: 10px;
    }
  }

  h2 {
    font-weight: 900;
    font-size: 18px;
  }

  p {
    font-weight: 600;
    font-size: 16px;
  }
`;

const Rules = (props: any) => {
  const { t } = useTranslation();
  const goBack = useCallback(() => {
    navigate(`/app/tutorial`);
  }, [navigate]);

  const texts = [...Array(15)].map((item, index) => ({
    index: index + 1,
    title: t(`rules_title_${index + 1}`),
    subtitle: t(`rules_subtitle_${index + 1}`),
  }));

  return (
    <div>
      <DefaultPageContainer align="center" pad={{ horizontal: 'large' }}>
        <Card
          style={{
            borderRadius: 30,
            width: '100%',
            padding: 0,
            marginBottom: 70,
          }}
          background="white"
          elevation="none"
        >
          <CardBody>
            <WrapContainer>
              <h1>{t('rules_main_title')}</h1>
              <p className="main_subtitle">{t('rules_main_subtitle')}</p>
              <div className="list">
                {texts.map(item => (
                  <div key={item.index}>
                    <h2>{item.title}</h2>
                    {/* <ReactMarkdown children={item.subtitle} /> */}
                    <ReactMarkdown
                      components={{
                        a: props => (
                          <a href={props.href} target="_blank" rel="noreferrer">
                            {props.children}
                          </a>
                        ),
                        // a: LinkRenderer,
                      }}
                    >
                      {item.subtitle}
                    </ReactMarkdown>
                  </div>
                ))}
              </div>
            </WrapContainer>
          </CardBody>
        </Card>
      </DefaultPageContainer>
      <Footer
        justify="center"
        height="40px"
        style={{
          paddingBottom: 30,
          position: 'fixed',
          width: '100%',
          bottom: 0,
        }}
      >
        <BottomNavigation
          buttonRightVisible={false}
          onCloseButtonPress={() => goBack()}
          isCloseButton
        />
      </Footer>
    </div>
  );
};

export default Rules;
