import { navigate } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, CheckBox, Footer } from 'grommet';
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick';

import { BottomNavigation } from '../../components/bottom-navigation/BottomNavigation';
import { CardImage } from '../../components/card-image/CardImage';
import DefaultPageContainer from '../../components/default-page-container/DefaultPageContainer';
import carosello1 from '../../images/utils/1_carosello.png';
import carosello2 from '../../images/utils/2_carosello.png';
import carosello3 from '../../images/utils/3_carosello.png';
import carosello4 from '../../images/utils/4_carosello.png';

import './tutorial.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

interface TutorialProps {
  userId: string;
  termsConditionAccepted: boolean;
  updateUserInfo: (payload: {
    id?: string;
    terms_condition_accepted: boolean;
  }) => void;
  retrieveGameUser: () => void;
}

const Tutorial = ({
  updateUserInfo,
  retrieveGameUser,
  userId,
  termsConditionAccepted,
}: TutorialProps) => {
  const { t, i18n } = useTranslation();
  const [isRulesChecked, setIsRulesChecked] = useState(termsConditionAccepted);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '40px',
    arrows: false,
    dotsClass: 'slick-dots slick-thumb',
  };

  useEffect(() => {
    setIsRulesChecked(termsConditionAccepted);
  }, [termsConditionAccepted]);

  const goToTerms = useCallback(() => {
    navigate(`/app/terms`);
  }, [navigate]);
  const goToRules = useCallback(() => {
    navigate(`/app/rules`);
  }, [navigate]);

  const navigateToExperience = useCallback(() => {
    if (isRulesChecked) {
      updateUserInfo({
        terms_condition_accepted: isRulesChecked,
      });
      retrieveGameUser();
    }
    navigate(`/app/experience`);
  }, [updateUserInfo, isRulesChecked, retrieveGameUser]);

  return (
    <>
      <DefaultPageContainer
        align="center"
        // pad={{ horizontal: 'large' }}
      >
        <Box>
          <Box
            align="center"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <p
              style={{
                fontWeight: '900',
                fontStyle: 'italic',
                fontSize: 26,
                width: '100%',
              }}
            >
              {t('home_title')}
            </p>
          </Box>
          <Box style={{ marginBottom: 40 }}>
            <Slider {...settings}>
              <div>
                <CardImage
                  textLeft
                  image={carosello1}
                  title={t('tutorial_slide_1_title')}
                  subtitle=""
                />
              </div>
              <div>
                <CardImage
                  textLeft
                  image={carosello2}
                  title={t('tutorial_slide_2_title')}
                  subtitle={t('tutorial_slide_2_subtitle')}
                />
              </div>
              <div>
                <CardImage
                  textLeft
                  image={carosello3}
                  title={t('tutorial_slide_3_title')}
                  subtitle={t('tutorial_slide_3_subtitle')}
                />
              </div>
              <div>
                <CardImage
                  textLeft
                  image={carosello4}
                  title={t('tutorial_slide_4_title')}
                  subtitle={t('tutorial_slide_4_subtitle')}
                />
              </div>
            </Slider>
          </Box>
          <Box align="center" pad={{ horizontal: 'large' }}>
            <Box onClick={() => goToRules()}>
              <span
                style={{
                  color: '#1481BE',
                  fontSize: 16,
                  fontWeight: '600',
                  textDecoration: 'underline',
                }}
              >
                {t('tutorial_rules_link')}
              </span>
            </Box>
            {!termsConditionAccepted && (
              <Box
                style={{ marginTop: 15, marginBottom: 40 }}
                flex
                direction="row"
                align="center"
              >
                <CheckBox
                  style={{ borderColor: 'blue' }}
                  checked={isRulesChecked}
                  onChange={event => setIsRulesChecked(event.target.checked)}
                />
                <Box flex direction="row">
                  {' '}
                  <Box>
                    <span style={{ marginLeft: 10, fontSize: 16 }}>
                      {t('tutorial_acept_link')}{' '}
                    </span>
                  </Box>
                  <Box onClick={() => goToTerms()}>
                    <span
                      style={{
                        color: '#1481BE',
                        fontSize: 16,
                        fontWeight: '600',
                        textDecoration: 'underline',
                        marginLeft: 5,
                      }}
                    >
                      {' '}
                      {t('tutorial_acept_term')}
                    </span>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </DefaultPageContainer>
      <Footer justify="center" height="40px" style={{ paddingBottom: 30 }}>
        <BottomNavigation
          rightButtonDisabled={!isRulesChecked}
          onRightButtonPress={() => navigateToExperience()}
          buttonTitle={t('next_button')}
        />
      </Footer>
    </>
  );
};

export default Tutorial;
