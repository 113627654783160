import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Heading,
    Paragraph,
    ResponsiveContext,
    Carousel,
    Image,
    Card,
    CardHeader,
    CardFooter,
    CardBody,
    Button,
    CheckBox
} from 'grommet';
import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import Slider from 'react-slick';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { QrReader } from 'react-qr-reader';
import DefaultPageContainer from '../../components/default-page-container/DefaultPageContainer';
import { CardImage } from '../../components/card-image/CardImage';
import threeStar from '../../images/utils/3stelle.png';
import card20Perc from '../../images/utils/Card20perc.png';
import carouselImage from '../../images/utils/carouselImage.png';

import { CardBrandProgress } from '../../components/card-brand-progress/CardBrandProgress';
import { CardPolicy } from '../../components/card-policy/CardPolicy';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
const ErrorFormatPage = (props: any) => {
    const size = React.useContext(ResponsiveContext);
    const { t, i18n } = useTranslation();
    const { navigate } = useI18next();


    const goToHaier = useCallback(() => {
        navigate(`/app/tutorial`);
    }, [navigate]);

    return (
        <DefaultPageContainer align="center" pad={{ horizontal: 'large' }}>
            <Card align="center" style={{ borderRadius: 30 }} height="medium" width="medium" background="white">
                <Box align='left' style={{ margin: 20, marginBottom: 10 }}>
                    <span style={{ fontWeight: '900', fontSize: 22, marginBottom: 10 }}>{t('error_page_title')}</span>
                    <span style={{ fontWeight: '500', fontSize: 16 }}>{t('error_page_subtitle')}</span>
                </Box>

                <CardBody pad="medium">
                    <Button style={{ minWidth: 290 }} primary onClick={() => goToHaier()} label={t('error_page_button')} color="#FFFFFF" size='large' />
                </CardBody>

            </Card>
        </DefaultPageContainer>
    );
};

export default ErrorFormatPage;
