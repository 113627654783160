import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Tutorial from './tutorial'
import { getUser, retrieveGameUser, updateUserInfo } from '../../state/modules/data';

const mapStateToProps = (state: any) => ({
    userId: getUser(state).id,
    termsConditionAccepted: getUser(state).termsConditionAccepted
})
    const mapDispatchToProps = (dispatch: Dispatch) => ({
        updateUserInfo: (payload: any) => dispatch(updateUserInfo(payload)),
        retrieveGameUser: () => dispatch(retrieveGameUser())

    })    

export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Tutorial);
  