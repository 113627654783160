import * as React from 'react';
import { Box, ResponsiveContext, Button } from 'grommet';
import { useCallback } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { Home, Close } from '@mui/icons-material';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import rgba from 'polished/lib/color/rgba';
import './bottomNavigation.css';

const StyledBox = styled(Box)`
  outline: none;
  user-select: none;
`;

export const BottomNavigation = ({
  buttonTitle,
  onRightButtonPress,
  onLeftButtonPress,
  onCloseButtonPress,
  rightButtonDisabled,
  isCloseButton,
  buttonRightVisible = true,
}: any) => {
  const size = React.useContext(ResponsiveContext);
  const { t, i18n } = useTranslation();
  const goHome = useCallback(() => {
    navigate(`/`);
  }, []);

  return (
    <StyledBox
      id="logo"
      height="100%"
      width="100%"
      justify="center"
      flex
      direction="row"
      align="center"
      className="backgroundGradient"
      style={{
        background:
          'linear-gradient(180deg, rgba(239, 241, 244, 0) 0%, #EFF1F4 23.96%, #EFF1F4 100%)',
        height: 70,
      }}
      pad={{
        left: size === 'small' ? '16px' : size,
        right: size === 'small' ? '16px' : size,
      }}
      focusIndicator={false}
    >
      {isCloseButton ? (
        <Button
          style={{
            padding: 10,
            paddingRight: 10,
            paddingLeft: 10,
            marginRight: 10,
            backgroundColor: '#F5522E',
            borderRadius: 50,
          }}
          icon={<Close style={{ color: 'white' }} />}
          plain
          onClick={() => onCloseButtonPress()}
        />
      ) : (
        <Button
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
            backgroundColor: 'white',
            border: 'solid',
            borderWidth: 2.5,
            borderColor: rgba(19, 155, 232, 0.2),
            borderRadius: 50,
            width: 45,
            minWidth: 45,
            minHeight: 45,
            color: '#139BE8',
          }}
          plain={false}
          onClick={() => {
            goHome();
            if (onLeftButtonPress) {
              onLeftButtonPress();
            }
          }}
        >
          <Home />
        </Button>
      )}
      {buttonRightVisible ? (
        <Button
          disabled={rightButtonDisabled}
          style={{ width: '100%', fontSize: 16, fontWeight: '900' }}
          primary
          onClick={() => onRightButtonPress()}
          label={buttonTitle}
          color={rightButtonDisabled ? '#709EB8' : '#FFFFFF'}
          size="large"
        />
      ) : (
        <Box style={{ width: '80%' }}> </Box>
      )}
    </StyledBox>
  );
};
