import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';
import { Footer } from 'grommet';
import DefaultPageContainer from '../../components/default-page-container/DefaultPageContainer';

import { CardPolicy } from '../../components/card-policy/CardPolicy';
import { BottomNavigation } from '../../components/bottom-navigation/BottomNavigation';


const RulesVoucher = (props: any) => {
  const { t, i18n } = useTranslation();
  const goBack = useCallback(() => {
    navigate(`/`);
  }, []);
  const toVoucher = useCallback(() => {
    navigate(`/app/voucher`);
  }, []);
  return (
    <>
      <DefaultPageContainer align="center" pad={{ horizontal: 'large' }}>
        <CardPolicy marginBottom={30} />
      </DefaultPageContainer>
      <Footer justify="center" height="40px" style={{ paddingBottom: 30 }}>
        <BottomNavigation onCloseButtonPress={() => goBack()} onRightButtonPress={() => toVoucher()} isCloseButton buttonTitle={t('rules_voucher_button')} />
      </Footer>
    </>
  );
};

export default RulesVoucher;
