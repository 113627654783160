import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Paragraph,
  ResponsiveContext,
  Carousel,
  Image,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Button,
  Text,
  CheckBox,
  Footer,
} from 'grommet';
import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import Slider from 'react-slick';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { QrReader } from 'react-qr-reader';
import { Close, Home, SportsEsports } from '@mui/icons-material';
import DefaultPageContainer from '../../components/default-page-container/DefaultPageContainer';
import { CardImage } from '../../components/card-image/CardImage';
import threeStar from '../../images/utils/3stelle.png';
import winningImage from '../../images/utils/4_carosello.png';

import './WinningPage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BottomNavigation } from '../bottom-navigation/BottomNavigation';
import { rgba } from 'polished';
import { navigate } from 'gatsby';

const WinningPage = ({ onClose }: any) => {
  const size = React.useContext(ResponsiveContext);
  const { t, i18n } = useTranslation();

  const goTo = useCallback(() => {
    navigate(`/app/tutorial`);
  }, [navigate]);

  return (
    <>
      <DefaultPageContainer align="center" style={{ marginBottom: 70 }}>
        <Button
          style={{
            position: 'absolute',
            top: 70,
            right: 10,
            padding: 10,
            paddingRight: 10,
            paddingLeft: 10,
            marginRight: 10,
            borderRadius: 50,
            backgroundColor: '#F5522E',
          }}
          icon={<Close style={{ color: 'white' }} />}
          color="#FFFFFF"
          plain
          onClick={() => onClose()}
        />

        <Box>
          <Box style={{ marginTop: 70 }}>
            <Image src={winningImage} fit="contain" />
            <Box style={{ paddingLeft: 20, paddingRight: 20 }}>
              <Image
                src={threeStar}
                style={{ marginTop: 10, maxHeight: 90 }}
                fit="contain"
              />
              <Box align="center">
                <Text
                  textAlign="center"
                  style={{ fontWeight: '900', fontSize: 22 }}
                >
                  {t('winning_page_title')}
                </Text>
                <Text
                  textAlign="center"
                  style={{ marginTop: 10, fontWeight: '500', fontSize: 16 }}
                >
                  {t('winning_page_subtitle')}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </DefaultPageContainer>
      <Box
        align="center"
        justify="between"
        flex
        direction="row"
        style={{
          position: 'fixed',
          bottom: 10,
          right: 0,
          left: 0,
          width: '100%',
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Button
          style={{
            padding: 10,
            borderRadius: 50,
            marginRight: 10,
            backgroundColor: 'white',
            border: 'solid',
            borderWidth: 2.5,
            borderColor: rgba(19, 155, 232, 0.2),
          }}
          icon={<Home />}
          color="#139BE8"
          plain={false}
          onClick={() => {
            navigate('/');
          }}
        />
        <button
          className="buttonModal"
          type="button"
          //   style={{ marginRight: 10, minWidth: '75%' }}
          onClick={() => {
            onClose();
          }}
        >
          <Text style={{ fontSize: 16, fontWeight: '900' }}>
            {t('experience_qr_footer_button')}
          </Text>
        </button>
      </Box>
    </>
  );
};

export default WinningPage;
