import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Card, CardBody, Footer, ResponsiveContext } from 'grommet';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ReactMarkdown from 'react-markdown';

import { BottomNavigation } from '../../components/bottom-navigation/BottomNavigation';
import { CardPolicy } from '../../components/card-policy/CardPolicy';
import DefaultPageContainer from '../../components/default-page-container/DefaultPageContainer';
import ht from '../../utils/translation';
import { WrapContainer } from '../rules/rules';

interface TermsAndConditionsProps {
  updateUserInfo: (payload: {
    id?: string;
    // eslint-disable-next-line camelcase
    terms_condition_accepted: boolean;
  }) => void;
}

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

const TermsAndConditions = ({ updateUserInfo }: TermsAndConditionsProps) => {
  const { t, i18n } = useTranslation();
  const [isReachedBottom, setIsReachedBottom] = useState(false);
  const refScrollableList = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(e => {
    const reachedBottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (reachedBottom) {
      setIsReachedBottom(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const goBack = useCallback(() => {
    navigate(`/app/tutorial`);
  }, []);

  const returnToTutorial = useCallback(() => {
    updateUserInfo({
      terms_condition_accepted: true,
    });
    navigate(`/app/tutorial`);
  }, [updateUserInfo]);

  const texts = [...Array(13)].map((item, index) => ({
    index: index + 1,
    title: t(`tec_title_${index + 1}`),
    subtitle: t(`tec_subtitle_${index + 1}`),
  }));

  // margin-inline-start: 0px;

  return (
    <div onScroll={handleScroll} ref={refScrollableList}>
      <DefaultPageContainer align="center" pad={{ horizontal: 'large' }}>
        <Card
          style={{
            borderRadius: 30,
            width: '100%',
            padding: 0,
            marginBottom: 70,
          }}
          background="white"
          elevation="none"
        >
          <CardBody>
            <WrapContainer>
              <h1>{t('terms_title')}</h1>
              <p className="main_subtitle">{t('rules_main_subtitle')}</p>
              <div className="list">
                {texts.map(item => (
                  <div key={item.index}>
                    <h2>{item.title}</h2>
                    <ReactMarkdown
                      components={{
                        a: props => (
                          <a href={props.href} target="_blank" rel="noreferrer">
                            {props.children}
                          </a>
                        ),
                        // a: LinkRenderer,
                      }}
                    >
                      {item.subtitle}
                    </ReactMarkdown>
                  </div>
                ))}
              </div>
            </WrapContainer>
          </CardBody>
        </Card>
      </DefaultPageContainer>
      <Footer
        justify="center"
        height="40px"
        style={{
          paddingBottom: 30,
          position: 'fixed',
          width: '100%',
          bottom: 0,
        }}
      >
        <BottomNavigation
          // buttonRightVisible={isTopReached}
          rightButtonDisabled={!isReachedBottom}
          onCloseButtonPress={() => goBack()}
          isCloseButton
          buttonTitle={t('terms_button')}
          onRightButtonPress={() => returnToTutorial()}
        />
      </Footer>
    </div>
  );
};

export default TermsAndConditions;
