import React from 'react';
import { Router } from '@reach/router';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import Home from '../../views/home';
import TermsAndConditions from '../../views/terms-and-conditions';
import Rules from '../../views/rules/rules';
import Tutorial from '../../views/tutorial';
import Experience from '../../views/experience';
import { rootConfig } from '../../config/root-config';
import './style.scss';
import RulesVoucher from '../../views/rules-voucher/RulesVoucher';
import Voucher from '../../views/voucher';
import WinningPage from '../winning-page/WinningPage';

const Main = ({ ...props }: any) => {
  const { language: lang } = React.useContext(I18nextContext);

  return (
    <Router
      basepath={`${lang !== 'en' ? '/:lang' : ''}${rootConfig.app_path}`}
      style={{ height: '100%' }}
    >
      <Home default path="/" />
      <TermsAndConditions path="/terms" />
      <RulesVoucher path="/vaucherRules" />
      <Rules path="/rules" />
      <Experience path="/experience" />
      <Voucher path="/voucher" />
      <Tutorial path="/tutorial" />
    </Router>
  );
};

export default Main;
